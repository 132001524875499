import styles from "./Footer.module.scss";

function Footer() {
  const tribe = {
    href: "https://www.tribeofdantours.com/testimonials/",
    target: "_blank",
  };
  const call = {
    href: "tell: +15133288852",
  };
  const facebook = {
    href: "https://www.facebook.com/danrglick",
    target: "_blank",
  };
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {/* <h1 className="title">Our toure leader</h1> */}
        <div className={styles.item}>
          <p>Read feedback and testimonies:</p>
          <a {...tribe} rel="noreferrer">
            TribeofDanTours.com
          </a>
        </div>
        <div className={styles.item}>
          <p>Contact me at: </p>
          <span className={styles.email}>danglick62@gmail.com</span>
        </div>
        <div className={styles.item}>
          <p>Call me:</p>
          <a {...call} rel="noreferrer">
            513 328 8852
          </a>
          {/* <p>
            Tel: <span className={styles.email}>513 328 8852</span>
          </p> */}
        </div>
        <div className={styles.item}>
          <p>Visit my page on Facebook :</p>
          <a {...facebook} rel="noreferrer">
            Facebook.com/danrglick
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
