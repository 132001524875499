import packingList from "./../../../src/img/Patmos/Suggested-Packing-List.pdf";
import styles from "./../../Styles/PackingList.module.scss";

function packinglist() {
  const adapters = {
    href: "https://www.amazon.com/European-VINTAR-International-Compatible-American/dp/B07WRWX15J/ref=sr_1_2_sspa?dib=eyJ2IjoiMSJ9.JJ0UVmA-bxmVMXCY7aXIagdtyCamhj1Pi8s9QfsWWVVUp-sfATi_DnpIzzoqPDikhjaHK-ISWgS5L0KugFcZddthCb-j-AiPJ-0l941r5Ze7ii0bDdWLgjXNBUVYEqeK0LcscpT4hS5eTrls3My5OE3UeAZ167MYWUjAmULY652slKASwR8uNywcoMXJyCWr66RrF8JPq8bbGGW_qezHp2uxp1se1Yl1nvdTuJ2kSwc.EcgaPd_xZivruh92SmgbReBEN8A-3ArPZTHEuNskML0&dib_tag=se&hvadid=695089785752&hvdev=c&hvlocphy=9014278&hvnetw=g&hvqmt=e&hvrand=10178278991618732994&hvtargid=kwd-2188297590651&hydadcr=26066_13693788&keywords=conversion%2Bplug%2Bfor%2Bgreece&qid=1733086079&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1 ",
    target: "_blank",
  };

  return (
    <div className="pageContainer">
      <p className="title">Suggested Packing List</p>
      <div className="printBox">
        <div className="print">
          <a href={packingList} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      <p className="text">
        My first advice to you is to pack light. Most people make the mistake of
        overpacking. Remember you will need to share the trunk space in a
        medium-sized car with three other people. We will have no place for your
        luggage if you take too much. If you take a check-in, make sure you only
        take one per couple (two people). And then each person can take a small
        carry-on or personal item, like a backpack. Another option is for each
        person to take a regular-size carry-on and a personal item without
        taking a check-in. If you have a question about luggage, please contact
        Dan at 513-328-8852 or email at danglick62@gmail.com.
      </p>

      <p className={`text ${styles.highlightOrange}`}>
        I recommend that you take……
      </p>
      <p className="text">A debit card so that you can get euros at an ATM.</p>
      <p className="text">
        You can take a small amount of American cash, but I wouldn’t take too
        much. Probably best to keep it at $300 or under. It can be difficult to
        convert your cash to euros.
      </p>
      <p className="text">
        I would take one or two credit cards. Most places will accept MasterCard
        and Visa as payment. Check with your bank to see if they charge a 3
        percent international transaction fee. Some do and some do not.
      </p>
      <p className={`text ${styles.highlightBlue}`}>
        LET YOUR BANK(S) KNOW THAT YOU WILL BE IN GREECE AND TO EXPECT CHARGES
        TO COME THROUGH ON YOUR DEBIT AND CREDIT CARDS. IF YOU FAIL TO DO THIS,
        THEY MAY SHUT DOWN YOUR CARD BECAUSE THEY SUSPECT FRAUD.
      </p>
      <p className={`text ${styles.highlightOrange}`}>
        Don’t forget your passport. And make sure it is valid (not due to expire
        for at least six months after you return home). If not, they will not
        let you board the airplane departing the USA.
      </p>
      <ul className={`text ${styles.list}`}>
        <li>
          Two copies of the main page of your passport (take one copy with you
          and leave one copy at home with someone). If you lose your passport,
          having a copy makes it easier to get a provisional one overseas.
        </li>
        <li>
          If you have medical insurance, take some information or your card with
          you proving you have it.
        </li>
        <li>
          The weather should be pleasant in September; however, it can be chilly
          in the evening and mornings, so a jacket is recommended.
        </li>
        <li>
          Clothes--not too many, about 3-5 changes of clothes. You don’t need
          any dress clothes.
        </li>
        <li>
          Laundry soap (very small amount, or you can buy some there and share
          it)
        </li>
        <li>
          Most people hand wash clothes in their hotel room. It is usually very
          expensive to send out your laundry for someone to do it.
        </li>
        <li>
          A washcloth if you use one. Most overseas hotels do not provide this.
        </li>
        <li>
          Adapters to plug in your electronics. Here is the link for Amazon
          where you can order an adapter that will work in Greece.{" "}
          <a {...adapters} rel="norferrer" className={styles.link}>
            Click here
          </a>
        </li>
        <li>Medicines and supplements that you usually take.</li>
        <li>Small Bible or Bible app on your phone.</li>
        <li>
          You can buy a Greece SIM card for your phone if you want one. This
          will give you internet and GPS wherever you go. You can also buy an
          eSim before you go. Check with your carrier.
        </li>
        <li>Take good walking/hiking shoes.</li>
        <li>
          Soft ear plugs to block any night noises that might keep you from
          sleeping and to block noisy children on the airplane. You can buy a
          package of these inexpensively at Walmart..{" "}
        </li>
        <li>Most hotels have hairdryers.</li>
        <li>
          I recommend that you don’t take anything that has a heating element in
          it. Curling iron, hair dryer, etc.
        </li>
      </ul>
    </div>
  );
}

export default packinglist;
