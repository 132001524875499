import { NavLink } from "react-router-dom";
import styles from "./Main.module.scss";
import picture from "./img/Main.jpg";
import pictureTwo from "./img/Main_2.jpg";
import pictureThree from "./img/Main_3.jpg";
import pictureFour from "./img/Main_4.jpg";
import pictureFive from "./img/Main_5.jpg";
import pictureSix from "./img/Main_6.jpg";

import logo from "./img/Logo.png";

function Main() {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.logo} src={logo} alt="logo" />
      </div>
      <div className={styles.trips}>
        <div className={styles.tripLinks}>
          <h1 className={styles.title}>Learn more about your trip</h1>
          <div className={styles.tripsGrid}>
            {/* change back to display grid when more trips are added */}
            {/* <div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Italy-April-2024">
                  Italy, April 2 - 11, 2024
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Greece-24" onClick={aprilTrip}

                >
                  Greece, April 22 - May 3, 2024
                </NavLink>
              </div>
              
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Poland-May-2024">
                  Poland, May 6-17, 2024
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="pageinMaking">
                  England, May 22 - June 1, 2024
                </NavLink>
              </div>

            </div> */}
            <div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Greece-april-2025">
                  Greece, April 1 - 12, 2025
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Germany-Spring-2025">
                  Germany, May 26 - June 6, 2025
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Switzerland/June-2025">
                  Switzerland, June 9 - 20, 2025
                </NavLink>
              </div>

              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Switzerland/August_2-13-2025">
                  Switzerland, August 2 - 13, 2025
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Switzerland/August_16-27-2025">
                  Switzerland, August 16 - 27, 2025
                </NavLink>
              </div>
            </div>

            <div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Patmos-september-2025">
                  Patmos, Athens & Corinth, September 24 - October 5, 2025
                </NavLink>
              </div>
              <div className={styles.listItem}>
                <div className={styles.circle}></div>
                <NavLink to="Israel-October-2025">
                  Israel and Petra Tour, October 15 - 29, 2025
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pictures}>
        <div>
          <img src={picture} alt="Jerusalem" />
        </div>
        <div>
          <img src={pictureTwo} alt="Jazreel valley" />
        </div>
        <div>
          <img src={pictureFour} alt="Coliseum in Caesarea" />
        </div>
        <div className={styles.extraPicture}>
          <img src={pictureThree} alt="The Sea of Galilee" />
        </div>
      </div>
    </div>
  );
}

export default Main;
