import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
import picture_3 from "../../img/Israel/IPDay_3.jpg";
import picture_4 from "../../img/Israel/IPDay_4.jpg";
import picture_5 from "../../img/Israel/IPDay_5.jpg";
import picture_6 from "../../img/Israel/IPDay_6.jpg";
import picture_7 from "../../img/Israel/IPDay_7.jpg";
import picture_8 from "../../img/Israel/IPDay_8.jpg";
import picture_9 from "../../img/Israel/IPDay_9.jpg";
import picture_10 from "../../img/Israel/IPDay_10.jpg";
import picture_11 from "../../img/Israel/IPDay_11.jpg";
import picture_12 from "../../img/Israel/IPDay_12.jpeg";
import picture_13 from "../../img/Israel/IPDay_13.jpg";
import itinerary from "../../img/Israel/Israel_Petra_Tour_2025.pdf";
import { IoClose } from "react-icons/io5";

function PetraIsraelItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Holy Land Tour</p>
      <p className="secondaryTitle">October 15-29, 2025</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Wednesday, October 15</h2>
        <p className="text">Depart the USA</p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Thuesday, October 16</h2>
        <p className="text">
          Arrive in Tel Aviv (overnight nearby airport).
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Friday, October 17</h2>
        <p className="text">
          Caesarea Maritima, Mt. Carmel, Megiddo (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button>
        </p>
        {/*Box 3*/}
        <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Caesarea Maritima, built by Herod the Great from 22-10 B.C., is
              packed with biblical history. It became a seat of Roman governance
              in Palestine for 500 years. It was here that governor Felix
              imprisoned the Apostle Paul two years before Paul appealed to
              Caesar, and Felix sent him to Rome. It was also the home of
              evangelist Philip and the origin of the Great Revolt of the Jews
              against the Romans (A.D. 66-73).
            </p>
            <p className="text">
              You will see the reconstructed theater erected by Herod, the ruins
              of his palace built right on the Mediterranean, and a
              thirteen-mile long aqueduct that carried water to the city from a
              spring at the base of Mount Carmel.
            </p>
            <p className="text">
              The harbor Herod had built here was some twenty-five acres in
              size. To do so, he imported from Italy forty-four-400-ton
              shiploads of volcanic ash, which hardens underwater. Using the ash
              to construct the breakwaters, Herod created the second largest
              harbor in the entire Roman world.
            </p>
            <p className="text">
              The story of Cornelius (Acts 10) took place in Caesarea. The door
              of salvation was open to the whole Gentile world, and surprisingly
              the first convert was a Roman centurion who was part of a hated
              occupying force, evidence of God's universal concern for all
              people.
            </p>
          </div>
        </div>
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Saturday, October 18</h2>
        <p className="text">
          Mt. Arbel, boat on the Sea of Galilee, the Jesus Boat Museum,
          Capernaum, Mt. of Beatitudes, Decapolis--east side of the Sea of
          Galilee (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 4*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will take a boat ride on the historic body of water
            - the Sea of Galilee.
          </p>
          <p className="text">
            The beautiful, sparkling Sea of Galilee is approximately seven miles
            wide, thirteen miles long, 150 feet deep, and 700 feet below sea
            level. It is the lowest freshwater body in the world. The Bible
            refers to it as the sea of Kinneret, Lake of Gennesaret, Sea of
            Tiberias, and the Sea of Galilee.
          </p>
          <p className="text">
            Mountains and deep valleys surround the lake, which accounts for the
            rise of storms, that can come up suddenly and be life-threatening to
            anyone caught away from land.
          </p>
          <p className="text">
            During the three years of Jesus' ministry, He lived on the north
            side of the lake in the town of Capernaum. Here He performed many
            miracles, including several on the lake itself: stilling the storm
            (Luke 8:22-25) and walking on its water (Mark 6:47-52).
          </p>
        </div>
      </div>
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Sunday, October 19</h2>
        <p className="text">
          Tel Dan, Caesarea Philippi, the Golan Heights, Mt. Hermon, Nimrod
          Castle, Valley of Tears (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 5*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            In most periods of biblical history, Dan was the northernmost city
            in Israel&apos;s territory. The phrase "from Dan to Beercheba"
            (Beersheva is misspelled) was an idiomatic way of referring to the
            entire country. Dan was originally a Canaanite city called Laish.
            However, the Tribe of Dan , migrating from their original territory
            near Joppa, captured Laish and turned it into Israelite territory.
          </p>
          <p className="text">
            At Tel Dan, one can view an impressive twenty-foot high Canaanite
            sun-dried, mud-brick gate that archaeologists unearthed. It is known
            as "Abraham&apos;s Gate" (Middle Bronze Age) because it is possible
            that Abraham passed through this gate in pursuit of his nephew Lot,
            who had been taken captive (Genesis 14:14).
          </p>
        </div>
      </div>

      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Monday, October 20</h2>
        <p className="text">
          Harod Springs (where Gideon chose his 300), Mt. Gilboa, Nazareth Brow,
          Nazareth Village (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 6*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            At the base of Mount Gilboa is Ein Harod, the spring of Harod.
            Scripture pinpoints this as the location where God reduced
            Gideon&apos;s troops from 32,000 to 300. Today, the water bubbling
            up from the mountain base is a cool, refreshing stream that creates
            a park for Israelis who often come here for holidays.
          </p>
          <p className="text">
            Up on the Mount Gilboa, the first king of Israel, King Saul,
            perished at the hands of the Philistines because he became a man God
            could not use due to his disobedience. Gideon, a man God could use
            because of his obedience, won a tremendous victory in the valley
            below.
          </p>
        </div>
      </div>
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Tuesday, October 21</h2>
        <p className="text">
          Jordan River (baptisms possible), Jericho, Qumran, the Dead Sea
          (overnight at Ein Bokek/the Dead Sea).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 7*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            The Dead Sea&apos;s surface is the lowest spot on earth at
            approximately 1,400 feet below sea level. The Dead Sea has the
            Jordan River as an inlet form the north, but it doesn't have an
            outlet. As water evaporates, it leaves an abundance of minerals,
            including sodium chloride, otherwise known as salt. Its salinity
            factor makes it ten times saltier than the ocean, which creates a
            harsh environment in which plants and animals cannot flourish.
          </p>
          <p className="text">
            The Jewish historian, Josephus, called the Dead Sea the Sea of Sodom
            because the city of Sodom was nearby. On the Southwestern shore is a
            ridge made from crystallized salt, and it measures at least 200 feet
            high and seven miles long. Local Arabs call it Mount Sodom.
          </p>
        </div>
      </div>
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Wednesday, October 22</h2>
        <p className="text">
          Masada, Jerusalem—visit the Old City—the Cardo, the Jewish Quarter,
          the Western Wall, the Jaffa Gate, the Zion Gate, the Western Wall of
          the Old City where Christ stood before Pontius Pilate and the crowd
          calling for His crucifixion (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 8*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            Jerusalem is situated on a central plateau 2,500 feet above sea
            level. No matter what direction one approaches the city, it is an
            upward climb, giving meaning to the scriptural statement of people
            going "up" to Jerusalem (Luke 2:42)
          </p>
          <p className="text">
            Modern Jerusalem is a sprawling city spread over ridges and valleys
            and is 100 times larger than Jerusalem was at any point in Bible
            times.
          </p>
          <p className="text">
            Ancient Jerusalem, the small city David conquered from the Jebusites
            a thousand years before Christ, was only a 10-acre ridge of land.
            But is was well fortified with city walls and three valleys to its
            east, west and south. To the north, in the direction of the Temple
            Mount and today&apos;s Old City, the land was flatter, and the city
            was more accessible. Interestingly, almost every army that breached
            the city walls and conquered Jerusalem did so from the north.
          </p>
        </div>
      </div>
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Thursday, October 23</h2>
        <p className="text">
          The Temple Mount, the Pool of Bethesda, Mt. Zion, the Upper Room, the
          grave of Oscar Schindler, the City of David, Hezekiah’s Tunnel, the
          Pool of Siloam (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 9*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            The water tunnel that runs from the Gihon Spring to the Pool of
            Siloam is called Hezekiah&apos;s Tunnel. In approximately 700 B.C.,
            King Hezekiah of Judah was preparing for the invasion of the
            Assyrian army led by King Sennacherib. Its purpose was to deny water
            to the Assyrian army since the Gihon was outside the city walls and
            channel its waters into the city, thus providing a water source to
            the city while under siege.
          </p>
          <p className="text">
            The workers dug through the bedrock, perhaps following an existing
            crack, for 1,700 feet from opposite directions 100 feet below the
            City of David. They met in the middle, and the water began flowing
            to the now created Pool of Siloam. It has been flowing for the last
            2,700 years.
          </p>
        </div>
      </div>

      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Friday, October 24</h2>
        <p className="text">
          The Mt of Olives, the Pater Noster, the Garden of Gethsemane, the
          Kidron Valley, the Garden Tomb (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 10*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            The Garden of Gethsemane is partway up the Mount of Olives directly
            east of the Temple Mount. It was in this general vicinity that Jesus
            met with His disciples on the fateful night of His betrayal. The
            word "Gethsemane" means "olive press," and the area was, and still
            is, home to many olive trees.
          </p>
        </div>
      </div>
      {/*Day 11*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Saturday, October 25</h2>
        <p className="text">
          Bethlehem, Sde Boker: the burial place of David Ben Gurion and his
          wife (first prime minister of modern Israel), the Wilderness of Zin
          and Paran (where the children of Israel wandered), the Ramon Crater
          (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(11);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 11*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[11] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(11);
            }}
          />
          <img className={styles.pictureFloat} src={picture_11} alt="Corinth" />
          <p className="text">
            This day will begin with a trip to Bethlehem, the birthplace of
            Jesus Christ. Our focus will be the Church of the Nativity and
            Manger Square.{" "}
          </p>
          <p className="text">
            Traveling south from Beersheba to Eilat and the Red Sea, the
            traveler must voyage through the Negev Desert. It is an approximate
            journey of 150 miles and passes though parts of the Wilderness of
            Zin and the Wilderness of Parah. Here the landscape turns into a
            rocky desert.
          </p>
          <p className="text">
            This desert has played as essential part in a number of Biblical
            narratives. When Hagar was driven out of the household of Abraham,
            she and Ishmael fled to the Wilderness of Parah. In this land, the
            children of Israel wandered for some 40 years, being tested and
            humbled by the Lord.
          </p>
        </div>
      </div>
      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12, Sunday October 26</h2>
        <p className="text">
          Visit the replica of the Old Testament tabernacle at Timna Park and
          eat/swim at the Red Sea (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(12);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 12*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[12] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(12);
            }}
          />
          <img className={styles.pictureFloat} src={picture_13} alt="Corinth" />
          <p className="text">
            Timna Park is located about 25 km (about 17 miles) north of Eilat in
            Israel’s Negev Desert. One of the largest attractions in the south
            of Israel the park sits in a beautifully majestic desert setting. It
            has amazing history and geology. It covers about 15,000 acres in a
            horseshoe-shaped valley surrounded by steep cliffs, with Mount
            Timna, the world’s first ever copper mine, standing tall in the
            center.
          </p>
          <p className="text">
            The historic connection between Timna Park and the Bible era led to
            the creation of a life size model of the Tabernacle. Timna provides
            the perfect desert background to display this fascinating replica of
            the Tabernacle, built in a complete, accurate and authentic
            reconstruction of the appearance, dimensions and colors of
            ‘Mishkan’, used by Israelites in desert. Inside visitors can view
            exact models of the altar, the copper sink, the table of showbread,
            the menorah, the ark of the covenant, vestments of the priests, and
            more.
          </p>
        </div>
      </div>

      {/*Day 13*/}
      <div>
        <h2 className={styles.dayNumber}>Day 13, Monday, October 27</h2>
        <p className="text">
          A day trip and tour to Petra in Jordan (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(13);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 13*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[13] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(13);
            }}
          />
          <img className={styles.pictureFloat} src={picture_12} alt="Corinth" />
          <p className="text">
            Petra, Arabic Baṭrā, ancient city, centre of an Arab kingdom in
            Hellenistic and Roman times, the ruins of which are in southwest
            Jordan. The city was built on a terrace, pierced from east to west
            by the Wadi Mūsā (the Valley of Moses)—one of the places where,
            according to tradition, the Israelite leader Moses struck a rock and
            water gushed forth. The valley is enclosed by sandstone cliffs
            veined with shades of red and purple varying to pale yellow, and for
            this reason Petra was called by the 19th-century English biblical
            scholar John William Burgon a “rose-red city half as old as Time.”
          </p>
          <p className="text">
            The Greek name Petra (“Rock”) probably replaced the biblical name
            Sela. Edomites are known to have occupied the area about 1200 BCE.
            Centuries later the Nabataeans, an Arab tribe, occupied it and made
            it the capital of their kingdom. Under Nabataean rule, Petra
            prospered as a centre of the spice trade that involved such
            disparate realms as China, Egypt, Greece, and India, and the city’s
            population swelled to between 10,000 and 30,000. When the Nabataeans
            were defeated by the Romans in 106 CE, Petra became part of the
            Roman province of Arabia but continued to flourish until changing
            trade routes caused its gradual commercial decline. After an
            earthquake (not the first) damaged the city in 551, significant
            habitation seems to have ceased.
          </p>
        </div>
      </div>
      {/*Day 14*/}
      <div>
        <h2 className={styles.dayNumber}>Day 14, Tuesday, October 28</h2>
        <p className="text">
          Travel back north to Tel Aviv, stop at the Valley of Elah (David and
          Goliath), supper at Joppa, depart for the airport, and leave late at
          night or early the next morning.{" "}
        </p>
      </div>
      {/*Day 15*/}
      <div>
        <h2 className={styles.dayNumber}>Day 15, Wednesday, October 29</h2>
        <p className="text">Fly back and arrive to the USA</p>
      </div>
    </div>
  );
}

export default PetraIsraelItinerary;
