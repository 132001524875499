import styles from "../../Styles/Generalinfo.module.scss";
import info from "../../img/Patmos/General-Information-Patmos-2025.pdf";

function Generalinfo() {
  const insurance = {
    href: "https://www.travelguard.com/",
    target: "_blank",
  };

  return (
    <div className="pageContainer">
      <div className="title">General Information</div>
      <div className="printBox">
        <div className="print">
          <a href={info} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <div>
        <div className={styles.paragraphTitle}>TRIP DATES</div>
        <p className="text">September 24 - October 5, 2025</p>
      </div>

      <div>
        <div className={styles.paragraphTitle}>GROUP SIZE</div>
        <p className="text">14 - 18</p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>COST</div>
        <p className="text">
          $1975 - $2185 plus airfare and food. This includes hotel
          accommodations with double occupancy, breakfasts in Athens and
          Corinth, land and sea transportation, costs of entry to sites. Single
          occupancy adds $775. The price does not include meals after breakfast
          and any additional activities not mentioned in the itinerary.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DOCUMENTS</div>
        <p className="text">
          You will need a valid passport, but you will NOT need a travel visa.
          Make sure your passport is valid and does not expire at least six
          months AFTER your re-entry date to the United States.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>INSURANCE</div>
        <p className="text">
          [OPTIONAL] If you wish to insure your trip, we recommend you use
          Travel Guard. There are many travel insurance companies out there,
          however, and one of them may prove to be a better option for you. You
          can visit
          <a {...insurance} rel="norferrer" className={styles.link}>
            TravelGuard.com
          </a>
          or call them at <span className={styles.highlight}>800-826-5248</span>
          . They have multiple options for insuring anything from the flight
          itself to the full cost of the trip. Most plans cover sickness,
          injury, or death of a family member.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>
          CANCELLATION AND REFUND POLICY
        </div>
        <p className="text">
          If you need to cancel your tour, you can do it provided it is 90 days
          before the tour date. After this, the trip is generally non-refundable
          due to contracts with hotels, guides, and transportation services.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DEPOSIT</div>
        <p className="text">
          A $300 deposit is required to reserve your seat on this trip. The
          group will be made up of the first 14-18 people to reserve their place
          with a deposit.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>HOW TO SIGN UP FOR THE TRIP</div>
        <p className="text">
          Send an email to Dan Glick at{" "}
          <span className={styles.highlight}>danglick62@gmail.com </span>{" "}
          stating your full passport name (exactly as it appears on your
          passport), your date of birth, phone number, email address, and
          physical address. Please state that you want to join the trip and then
          mail your deposit to Dan. The address is provided below.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PAYMENT POLICY</div>
        <p className="text">
          Only a check is accepted as a form of payment. Checks should be made
          to: Tribe of Dan Tours and mailed to Dan Glick, 9850 Cedar Cove,
          Alexandria, KY 41001.
        </p>
      </div>

      <div>
        <div className={styles.paragraphTitle}>PRE-TRIP MATERIALS</div>
        <p className="text">
          Tribe of Dan Tours provides the following documents: 1. A list of
          YouTube videos that you are strongly urged to watch. 2. A list of
          reading materials that will give you a foundation for our study of
          Revelation. 3. A suggestive packing list. 4. A prayer guide. 5. Travel
          Advice. 6. A Gallery of Photos. You can download these on this site by
          clicking the Print PDF button at the top of each page. The file will
          open in your browser. You can print it out from there or save it to
          your device by clicking the download icon in your browser. You will
          find the file in the Downloads folder on your device.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PHYSICAL REQUIREMENTS</div>
        <p className="text">
          There is substantial walking on this trip. It is recommended that you
          get a good amount of walking exercise in the weeks preceding the trip.
          There are no special vaccines required for entry into Greece.
        </p>
      </div>
    </div>
  );
}

export default Generalinfo;
