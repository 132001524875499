import PatmosMenu from "./PatmosMenu";
import Footer from "../../Components/Footer";
import { Outlet } from "react-router-dom";
import styles from "../../Styles/Trip.module.scss";

function PatmosTrip() {
  return (
    <div>
      <div className={`${styles.menuPosition} ${styles.container}`}>
        <PatmosMenu />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PatmosTrip;
