import "../../Styles/Galery.scss";
import React from "react";

//large images
import original_1 from "../../img/Israel/Israel_gallery_1.jpg";
import original_2 from "../../img/Israel/Israel_gallery_2.jpg";
import original_3 from "../../img/Israel/Israel_gallery_3.jpg";
import original_4 from "../../img/Israel/Israel_gallery_4.jpg";
import original_5 from "../../img/Israel/Israel_gallery_5.jpg";
import original_6 from "../../img/Israel/Israel_gallery_6.jpg";
import original_7 from "../../img/Israel/Israel_gallery_7.jpg";
import original_8 from "../../img/Israel/Israel_gallery_8.jpg";
import original_9 from "../../img/Israel/Israel_gallery_9.jpg";
import original_10 from "../../img/Israel/Israel_gallery_10.jpg";
import original_11 from "../../img/Israel/Israel_gallery_11.jpg";
import original_12 from "../../img/Israel/Israel_gallery_12.jpg";
import original_13 from "../../img/Israel/Israel_gallery_13.jpg";
import original_14 from "../../img/Israel/Israel_gallery_14.jpg";
import original_15 from "../../img/Israel/Israel_gallery_15.jpg";
import original_16 from "../../img/Israel/Israel_gallery_16.jpg";
import original_17 from "../../img/Israel/Israel_gallery_17.jpg";
import original_18 from "../../img/Israel/Israel_gallery_18.jpg";
import original_19 from "../../img/Israel/Israel_gallery_19.jpg";
import original_20 from "../../img/Israel/Israel_gallery_20.jpg";

//small images
import small_1 from "../../img/Israel/Israel_gallery_1_small.jpg";
import small_2 from "../../img/Israel/Israel_gallery_2_small.jpg";
import small_3 from "../../img/Israel/Israel_gallery_3_small.jpg";
import small_4 from "../../img/Israel/Israel_gallery_4_small.jpg";
import small_5 from "../../img/Israel/Israel_gallery_5_small.jpg";
import small_6 from "../../img/Israel/Israel_gallery_6_small.jpg";
import small_7 from "../../img/Israel/Israel_gallery_7_small.jpg";
import small_8 from "../../img/Israel/Israel_gallery_8_small.jpg";
import small_9 from "../../img/Israel/Israel_gallery_9_small.jpg";
import small_10 from "../../img/Israel/Israel_gallery_10_small.jpg";
import small_11 from "../../img/Israel/Israel_gallery_11_small.jpg";
import small_12 from "../../img/Israel/Israel_gallery_12_small.jpg";
import small_13 from "../../img/Israel/Israel_gallery_13_small.jpg";
import small_14 from "../../img/Israel/Israel_gallery_14_small.jpg";
import small_15 from "../../img/Israel/Israel_gallery_15_small.jpg";
import small_16 from "../../img/Israel/Israel_gallery_16_small.jpg";
import small_17 from "../../img/Israel/Israel_gallery_17_small.jpg";
import small_18 from "../../img/Israel/Israel_gallery_18_small.jpg";
import small_19 from "../../img/Israel/Israel_gallery_19_small.jpg";
import small_20 from "../../img/Israel/Israel_gallery_20_small.jpg";

import ImageGallery from "react-image-gallery";
const imagesLarge = [
  {
    original: original_1,
  },
  {
    original: original_2,
  },
  {
    original: original_3,
  },
  {
    original: original_4,
  },
  {
    original: original_5,
  },
  {
    original: original_6,
  },
  {
    original: original_7,
  },
  {
    original: original_8,
  },
  {
    original: original_9,
  },
  {
    original: original_10,
  },
  {
    original: original_11,
  },
  {
    original: original_12,
  },
  {
    original: original_13,
  },
  {
    original: original_14,
  },

  {
    original: original_15,
  },
  {
    original: original_16,
  },
  {
    original: original_17,
  },
  {
    original: original_18,
  },
  {
    original: original_19,
  },
  {
    original: original_20,
  },
];

const imagesSmall = [
  {
    original: small_1,
  },
  {
    original: small_2,
  },
  {
    original: small_3,
  },
  {
    original: small_4,
  },
  {
    original: small_5,
  },
  {
    original: small_6,
  },
  {
    original: small_7,
  },
  {
    original: small_8,
  },
  {
    original: small_9,
  },
  {
    original: small_10,
  },
  {
    original: small_11,
  },
  {
    original: small_12,
  },
  {
    original: small_13,
  },
  {
    original: small_14,
  },

  {
    original: small_15,
  },
  {
    original: small_16,
  },
  {
    original: small_17,
  },
  {
    original: small_18,
  },
  {
    original: small_19,
  },
  {
    original: small_20,
  },
];
function PetraIsraelGallery() {
  let size = window.innerWidth;
  return (
    <div className="pageContainer space">
      <div className="gallery-container">
        <div className="image-gallery-wrapper pictures">
          <ImageGallery
            items={size > 600 ? imagesLarge : imagesSmall}
            disableThumbnailScroll={true}
            showThumbnails={false}
            showBullets={true}
          />
        </div>
      </div>
    </div>
  );
}

export default PetraIsraelGallery;
