import "../../Styles/Galery.scss";
import React, { useState, useEffect } from "react";

import picture_1 from "../../img/Patmos/Gallery-1.jpg";
import picture_2 from "../../img/Patmos/Gallery-2.jpg";
import picture_3 from "../../img/Patmos/Gallery-3-large.jpg";
import picture_4 from "../../img/Patmos/Gallery-4-large.jpg";
import picture_5 from "../../img/Patmos/Gallery-5-large.jpg";
import picture_6 from "../../img/Patmos/Gallery-6-large.jpg";
import picture_7 from "../../img/Patmos/Gallery-7-large.jpg";
import picture_8 from "../../img/Patmos/Gallery-8-large.jpg";
import picture_9 from "../../img/Patmos/Gallery-9-large.jpg";
import picture_10 from "../../img/Patmos/Gallery-10-large.jpg";
import picture_11 from "../../img/Patmos/Gallery-11-large.jpg";
import picture_12 from "../../img/Patmos/Gallery-12-large.jpg";
import picture_13 from "../../img/Patmos/Gallery-13-large.jpg";
import picture_14 from "../../img/Patmos/Gallery-14-large.jpg";
import picture_15 from "../../img/Patmos/Gallery-15-large.jpg";
import picture_16 from "../../img/Patmos/Gallery-16-large.jpg";

//small pictures
import picture_1_small from "../../img/Patmos/Gallery-1.jpg";
import picture_2_small from "../../img/Patmos/Gallery-2.jpg";
import picture_3_small from "../../img/Patmos/Gallery-3-small.jpg";
import picture_4_small from "../../img/Patmos/Gallery-4-small.jpg";
import picture_5_small from "../../img/Patmos/Gallery-5-small.jpg";
import picture_6_small from "../../img/Patmos/Gallery-6-small.jpg";
import picture_7_small from "../../img/Patmos/Gallery-7-small.jpg";
import picture_8_small from "../../img/Patmos/Gallery-8-small.jpg";
import picture_9_small from "../../img/Patmos/Gallery-9-small.jpg";
import picture_10_small from "../../img/Patmos/Gallery-10-small.jpg";
import picture_11_small from "../../img/Patmos/Gallery-11-small.jpg";
import picture_12_small from "../../img/Patmos/Gallery-12-small.jpg";
import picture_13_small from "../../img/Patmos/Gallery-13-small.jpg";
import picture_14_small from "../../img/Patmos/Gallery-14-small.jpg";
import picture_15_small from "../../img/Patmos/Gallery-15-small.jpg";
import picture_16_small from "../../img/Patmos/Gallery-16-small.jpg";

import ImageGallery from "react-image-gallery";
const imagesLarge = [
  {
    original: picture_1,
  },
  {
    original: picture_2,
  },
  {
    original: picture_3,
  },
  {
    original: picture_4,
  },
  {
    original: picture_5,
  },
  {
    original: picture_6,
  },
  {
    original: picture_7,
  },
  {
    original: picture_8,
  },
  {
    original: picture_9,
  },
  {
    original: picture_10,
  },
  {
    original: picture_11,
  },
  {
    original: picture_12,
  },
  {
    original: picture_13,
  },
  {
    original: picture_14,
  },

  {
    original: picture_15,
  },
  {
    original: picture_16,
  },
];
const imagesSmall = [
  {
    original: picture_1_small,
  },
  {
    original: picture_2_small,
  },
  {
    original: picture_3_small,
  },
  {
    original: picture_4_small,
  },
  {
    original: picture_5_small,
  },
  {
    original: picture_6_small,
  },
  {
    original: picture_7_small,
  },
  {
    original: picture_8_small,
  },
  {
    original: picture_9_small,
  },
  {
    original: picture_10_small,
  },
  {
    original: picture_11_small,
  },
  {
    original: picture_12_small,
  },
  {
    original: picture_13_small,
  },
  {
    original: picture_14_small,
  },

  {
    original: picture_15_small,
  },
  {
    original: picture_16_small,
  },
];

function ItalyGallery() {
  let size = window.innerWidth;

  console.log(size);

  return (
    <div className="pageContainer space">
      <div className="gallery-container">
        <div className="image-gallery-wrapper pictures">
          <ImageGallery
            items={size > 600 ? imagesLarge : imagesSmall}
            disableThumbnailScroll={true}
            showThumbnails={false}
            showBullets={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ItalyGallery;
