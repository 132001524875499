import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import VideoBox from "../../Components/VideoBox";

//Greece geography
const videoList_1 = [
  {
    id: 1,
    title: "Geography Now! Greece",
    duration: "13:46 mins",
    sourceLink: "https://www.youtube.com/embed/Cp3yabqF4Uw?si=3dzXvJquem_6jtB8",
  },
];
//Revelation explained
const videoList_2 = [
  {
    id: 1,
    title: "Book of Revelation Summary: A Complete Animated Overview (Part 1)",
    duration: "11:48 mins",
    sourceLink: "https://www.youtube.com/embed/5nvVVcYD-0w?si=5Yp6Z3Wayb7MAbxM",
  },
  {
    id: 2,
    title: "Book of Revelation Summary: A Complete Animated Overview (Part 2)",
    duration: "11:50 mins",
    sourceLink: "https://www.youtube.com/embed/QpnIrbq2bKo?si=1wzG2oP-xfZ-D7sQ",
  },
];

//Patmos and the Apostle John
const videoList_3 = [
  {
    id: 1,
    title: "Who was the famous Apostle banished to the Island of Patmos",
    duration: "4:22 mins",
    sourceLink: "https://www.youtube.com/embed/ZfHWPq1BAa4?si=sUxLJB370IO8FHPP",
  },
  {
    id: 2,
    title: "Pentecost to Patmos",
    duration: "5:58 mins",
    sourceLink: "https://www.youtube.com/embed/4E3HxVZ7QFg?si=ah0Wunswi4T7wtUe",
  },

  {
    id: 3,
    title: "“Holy Cave of the Apocalypse” Patmos Greece“",
    duration: "5:59 mins",
    sourceLink: "https://www.youtube.com/embed/fKi2OOvQHRg?si=xOq2A3Q8pqSBBWli",
  },
  {
    id: 4,
    title: "What happened to John at the Island of Patmos",
    duration: "3:58 mins",
    sourceLink: "https://www.youtube.com/embed/qFMt6Pvdz00?si=AYyCcCzuB5RZvULs",
  },
];
//The island of Patmos

const videoList_4 = [
  {
    id: 1,
    title: "Top Five Things to See on Patmos, Greece",
    duration: "7:24 mins",
    sourceLink: "https://www.youtube.com/embed/AIgYePo9E7Q?si=hJs-fBQwh6H0qFNX",
  },
  {
    id: 2,
    title:
      "Patmos Greece Travel Vlog 2024 Part 1 | Explore Greece 4K, Greek Islands, Beaches, Greek Food, Chora",
    duration: "10:03 mins",
    sourceLink: "https://www.youtube.com/embed/IJ6zxHSKsBU?si=5gFfLre8GS5dDFAG",
  },
  {
    id: 3,
    title:
      "Patmos Greece Travel Vlog 2024 Part 2 | Discover Greece 4K, Greek Islands Guide, Chora Streets, View",
    duration: "13:20 mins",
    sourceLink: "https://www.youtube.com/embed/CJwAVRcx2Eg?si=PhsDWRR-pXjsxgZx",
  },
  {
    id: 4,
    title:
      "Patmos Greece Travel Vlog 2024 Part 3 | Explore Greece 4K, Greek Beaches, Greek Food, Skala Streets",
    duration: "18:38 mins",
    sourceLink: "https://www.youtube.com/embed/qfyMiZa6obo?si=pxgCFABHivtt0ATx",
  },
];
//Athens
const videoList_5 = [
  {
    id: 1,
    title:
      "Athens, Greece: Ancient Acropolis and Agora - Rick Steves’ Europe Travel Guide",
    duration: "4:32 mins",
    sourceLink: "https://www.youtube.com/embed/xP-FsX0QW88?si=fSvpKDA8qBYbKx6D",
  },
  {
    id: 2,
    title:
      "Did the Apostle Paul really Visit Ancient Athens? Drive through History",
    duration: "5:56 mins",
    sourceLink: "https://www.youtube.com/embed/WeohDhepxOM?si=ndTYxEvs2WR0t3l8",
  },
  {
    id: 3,
    title: "Ancient Athens Explained",
    duration: "16:23 mins",
    sourceLink: "https://www.youtube.com/embed/JPs_LDOQ_yw?si=X_CyMWtp8p_e9z4N",
  },
  {
    id: 4,
    title: "Virtual tour in ancient Athens (5th century BC)",
    duration: "12:11 mins",
    sourceLink: "https://www.youtube.com/embed/ulAxMLJ7O7M?si=h75UVKuceiWzx7Jc",
  },
];

//Corinth
const videoList_6 = [
  {
    id: 1,
    title: "What is the Story of Ancient Corinth?",
    duration: "5:15 mins",
    sourceLink: "https://www.youtube.com/embed/jiRM_FEqJmI?si=RZOTHI8joyV8emWy",
  },
  {
    id: 2,
    title: "How long did Paul live in Corinth?",
    duration: "5:08 mins",
    sourceLink: "https://www.youtube.com/embed/BWYeIaNsTPc?si=plqMhXoUjiNJiMQa",
  },
];

function VideoStudy() {
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="pageContainer">
      <div className="title">
        Patmos tour: Documentaries and Reading Materials
      </div>
      {/* <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
      </p> */}
      <h2 className={`secondaryTitle ${styles.subtitle}`}>
        Recommended Video Materials:
      </h2>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Greece Geography
      </div>

      {videoList_1.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Revelation Explained
      </div>

      {videoList_2.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        More video material on Revelation to come{" "}
      </div>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Patmos and the Apostle John{" "}
      </div>

      {videoList_3.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        The island of Patmos{" "}
      </div>
      {videoList_4.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Athens</div>
      {videoList_5.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Corinth</div>
      {videoList_5.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <h2 className={`secondaryTitle ${styles.subtitle}`}>
        Recommended Reading Materials:
      </h2>
      <ul className={styles.bookList}>
        <li className="text">
          <span>
            Charts on the Book of Revelation: Literary, Historical, and
            Theological Perspectives
          </span>{" "}
          (Kregel Charts of the Bible and Theology) by Mark Wilson.
        </li>
        <li className="text">
          <span>Revelation</span> (Zondervan Illustrated Bible Backgrounds
          Commentary) (p. 49) by Mark W. Wilson. Zondervan Academic.
        </li>
        <li className="text">
          <span>
            Victory through the Lamb: A Guide to Revelation in Plain Language
          </span>{" "}
          by Mark Wilson. Lexham Press.
        </li>
        <li className="text">
          <span>
            Discovering Revelation: Content, Interpretation, Reception
          </span>{" "}
          by David A. DeSilva. (Discovering Biblical Texts (DBT)) (p. 258). Wm.
          B. Eerdmans Publishing Co.
        </li>
      </ul>
    </div>
  );
}

export default VideoStudy;
