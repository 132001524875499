import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureIsrael}>
      <div className={styles.titleBox}>
        <h1>Visit the land of the Bible</h1>
        <p className={styles.title}>Israel & Petra</p>
        <p className={styles.dates}>October 15 - 29, 2025</p>
        <div className={styles.description}>
          <p>
            This comprehensive trip to Israel will take you from Mount Hermon in
            the far north to the Red Sea in the far south. It also includes an
            exciting day trip to Petra in Jordan. This is truly the Cadillac of
            Holy Land tours.{" "}
          </p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
