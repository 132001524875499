import { useEffect, useState } from "react";
import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";
import { useLocation } from "react-router-dom";

function Homepage({ trip }) {
  console.log(trip);

  return (
    <div className={styles.backgroundPicture}>
      <div className={styles.titleBox}>
        <h1>Go to the enchanting land of</h1>
        <p className={styles.title}>Greece</p>
        <p className={styles.dates}>April 1 - 12, 2025</p>
        <div className={styles.description}>
          <p>Walk in the footsteps of St. Paul.</p>
          <p>Visit great historical and cultural sites all over this land.</p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;

// //<div>
//         <img
//           className={styles.homePhoto}
//           src={GreeceTrip}
//           alt="Greece monastery"
//         />
//       </div>
