import styles from "../../Styles/Generalinfo.module.scss";
import info from "../../img/Israel/General_Information_Israel_2025.pdf";

function Generalinfo() {
  const insurance = {
    href: "https://www.travelguard.com/",
    target: "_blank",
  };

  return (
    <div className="pageContainer">
      <div className="title">General Information</div>
      <div className="printBox">
        <div className="print">
          <a href={info} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <div>
        <div className={styles.paragraphTitle}>TRIP DATES</div>
        <p className="text">October 15-29, 2025</p>
      </div>

      <div>
        <div className={styles.paragraphTitle}>GROUP SIZE</div>
        <p className="text">28</p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>COST</div>
        <p className="text">
          $3,220-$3,400 plus airfare and food after breakfast (except for two
          evening meals). The price includes hotel accommodations/double
          occupancy, all breakfasts (except for the three days at Eilat), costs
          of entry to sites, local guides, the day trip to Petra, and the use of
          an audio tour guide sound receiver. Single occupancy adds $1,025. The
          price does not include any additional activities not mentioned in the
          itinerary.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DOCUMENTS</div>
        <p className="text">
          You will need a valid passport, but you will NOT need a travel visa.
          Make sure your passport is valid and does not expire at least six
          months AFTER your re-entry date to the United States. This date is May
          1, 2026.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>INSURANCE</div>
        <p className="text">
          [OPTIONAL] If you wish to insure your trip, we recommend you use
          Travel Guard. There are many travel insurance companies out there,
          however, and one of them may prove to be a better option for you. You
          can visit
          <a {...insurance} rel="norferrer" className={styles.link}>
            TravelGuard.com
          </a>
          or call them at <span className={styles.highlight}>800-826-5248</span>
          . They have multiple options for insuring anything from the flight
          itself to the full cost of the trip. Most plans cover sickness,
          injury, or death of a family member.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>
          CANCELLATION AND REFUND POLICY
        </div>
        <p className="text">
          If you need to cancel your tour, you can do it, provided it is 90 days
          before the tour date. After this, the trip is generally non-refundable
          due to contracts with hotels, guides, and transportation services.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>HOW TO SIGN UP FOR THE TRIP</div>
        <p className="text">
          Contact Dan Glick at{" "}
          <span className={styles.highlight}> danglick62@gmail.com</span> and
          provide your full passport name (exactly as it appears on your
          passport), date of birth, phone number, email address, and physical
          address. Then send in your deposit.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>DEPOSIT</div>
        <p className="text">
          A $500 deposit is required to reserve your seat on this trip. The
          group will be made up of the first 14 people to reserve their place
          with a deposit. Only a check is accepted as a form of payment. Checks
          should be made to Tribe of Dan Tours and mailed to Dan Glick, 9850
          Cedar Cove, Alexandria, KY 41001.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PAYMENT POLICY</div>
        <p className="text">
          <span>The final payment will be due 90 days before departure.</span>{" "}
          Only a check is accepted as a form of payment. Checks should be made
          to Tribe of Dan Tours and mailed to Dan Glick, 9850 Cedar Cove,
          Alexandria, KY 41001.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>
          AUDIO TOUR GUIDE SOUND SYSTEM
        </div>
        <p className="text">
          Tribe of Dan Tours makes use of an audio tour guide sound receiver.
          With this receiver and the accompanying earbud, you will be able to
          hear your guide wherever you are in the group.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PRE-TRIP MATERIALS</div>
        <p className="text">
          Tribe of Dan Tours provides the following documents: 1) An itinerary
          2) A list of YouTube videos that one can watch about the country and
          sites of Germany. This is very helpful information prior to the trip,
          and Dan strongly encourages all tourists to take advantage of this and
          watch them in the months prior to the trip. 3) A Scripture reading
          guide. 4) A suggestive packing list. 5) A prayer guide. 6) Some travel
          advice. 7) A gallery of photos. You can download these on this site by
          clicking the Print PDF button at the top of each page. The file will
          open in your browser. You can print it out from there or save it to
          your device by clicking the download icon in your browser. You will
          find the file in the Downloads folder on your device.
        </p>
      </div>
      <div>
        <div className={styles.paragraphTitle}>PHYSICAL REQUIREMENTS</div>
        <p className="text">
          There is substantial walking on this trip. It is recommended that you
          get a good amount of walking exercise in the weeks preceding the trip.
          There are no special vaccines required for entry into Israel.
        </p>
      </div>
    </div>
  );
}

export default Generalinfo;
