import styles from "../../Styles/Prayer.module.scss";
import pictureOne from "../../img/Patmos/Prayer-1.jpg";
import pictureTwo from "../../img/Patmos/Prayer-2.jpg";
import pictureThree from "../../img/Patmos/Prayer-3.jpg";
import prayerPdf from "../../img/Patmos/Prayer-Patmos.pdf";

function Prayer() {
  return (
    <div className="pageContainer">
      <p className="title">Prayer for our tour of Patmos, Athens & Corinth</p>
      <div className="printBox">
        <div className="print">
          <a href={prayerPdf} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      <p className="secondaryTitle">
        I invite you to pray this (or something like it) every day for the 30
        days before departure.
      </p>

      <p className="text">
        Thank you, God, for the privilege of visiting the beautiful island of
        Patmos. May this trip be a time when we develop a greater appreciation
        for the beauty in nature and for the God who created it all.
      </p>
      <p className="text">
        Let our pre-trip studies prove to be enlightening and adequate
        preparation for the on-field experience. May our daily study of the book
        of Revelation on Patmos lead us into a deeper relationship with God.
        Since there is a special blessing promised in connection with reading,
        listening, and obeying the words of the book of Revelation, let this
        reflect our experience on this trip.
      </p>
      <p className="text">
        We seek divine protection for all life and property. Let nothing be lost
        or hurt. Save us from illness and any digestive distress from new or
        different foods. Let there be no medical emergencies for anyone in the
        group. We pray for smooth flights and transportation that operate
        without mechanical problems. Deliver us from accidents while traveling.
      </p>
      <p className="text">
        We know how important it is to get proper rest at night, so we pray that
        our accommodations will be pleasant and sufficient. May they be quiet
        and restful so we are able to get the sleep we need to keep up our
        strength for the busy days. May we adjust quickly to a new time zone.
      </p>
      <p className="text">
        Work on our behalf so that the trip has the feeling that God is leading
        and providing. We pray that the schedule will come together just right
        and that we will not feel a spirit of hurry and pressure. Give our group
        unity and camaraderie. May each member of the team look after and care
        for one another. Let us be thoughtful and considerate. May new
        friendships develop from our interactions.
      </p>
      <p className="text">
        If the trip presents any challenges, let us face them with flexibility,
        courage, and faith, not with doubt and complaints. Banish fear from all
        minds and hearts.
      </p>
      <p className="text">
        We pray, Lord, that you will give our group leaders (Dan and Martha
        Glick) special strength. Empower them with health and strength. Let them
        always be under your influence so that they will make the right
        decisions for the trip.
      </p>
      <p className="text">
        Lord, let us all be a good testimony for You wherever we are and with
        whomever we meet. As your ambassadors, let the light of Christ be so
        radiant in us that all will know we are God's people. Help us to be
        sensitive to the cultural differences we find in Greece. Lead us to the
        right words and actions for each context in which we find ourselves.
        Given a chance, let us be a witness for You.
      </p>
      <p className="text">
        To You, the Alpha and Omega, we pray that our trip may be blessed in
        every way from beginning to end. May we return home safely, feeling
        refreshed and blessed. We ask this in the name of Jesus Christ our Lord,
        AMEN!
      </p>
      <div className={styles.pictureBox}>
        <div>
          <img
            className={styles.picture}
            src={pictureOne}
            alt="Israel, people praying"
          />
        </div>
        <div className={styles.hide}>
          <img
            className={styles.picture}
            src={pictureTwo}
            alt="Israel, people praying"
          />
        </div>
        <div>
          <img
            className={styles.picture}
            src={pictureThree}
            alt="Israel, people praying"
          />
        </div>
      </div>
    </div>
  );
}

export default Prayer;
