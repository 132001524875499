import { useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../../Styles/Itinerary.module.scss";

import itinerary from "../../img/Patmos/Itinerary-Patmos-2025.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Patmos/Itinerary-1.jpg";
import pictureTwo from "./../../img/Patmos/Itinerary-2.jpg";
import pictureThree from "./../../img/Patmos/Itinerary-3.jpg";

function PatmosItinerary() {
  const trip = [
    {
      day: "Day 1, Wednesday, September 24",
      text: "Fly from the USA to Athens, Greece.",
    },
    {
      day: "Day 2, Thursday, September 25",
      text: "Arrive to Athens (overnight in Athens).",
    },
    {
      day: "Day 3, Friday, September 26",
      text: "Tour the Parthenon, Mars Hill, and the Agora. In the evening, we will depart at 6:00 PM from Piraeus Port on board a large ferry to the Island of Patmos (overnight in our room on the ferry part of the night and in our villa on Patmos the rest of the night).",
    },
    {
      day: "Day 4, Saturday, September 27",
      text: "Arrive at approximately 2:00 AM to Patmos. Taxi to our villas at Eirini Luxury Hotel Villas located in Sapsila. Sleep until late morning. In the afternoon, we will pick up our car and scooter rentals. In the evening, we will begin our study of Revelation (overnight on Patmos).",
    },
    {
      day: "Day 5, Sunday, September 28",
      text: "Over the course of these five days, we will be studying the book of Revelation. We will have two sessions per day. One at the villas where we will stay, and one will be at a different place on the island each day. With our free time, there will be plenty of time for hiking, eating, swimming, relaxing, exploring, riding, and driving all over the island (overnight on Patmos).",
    },
    {
      day: "Day 6, Monday, September 29",
      text: "Studying the Book of Revelation on the Island of Patmos.",
    },
    {
      day: "Day 7, Tuesday, September 30",
      text: "Studying the Book of Revelation on the Island of Patmos.",
    },
    {
      day: "Day 8, Wednesday, October 1",
      text: "Studying the Book of Revelation on the Island of Patmos.",
    },
    {
      day: "Day 9, Thursday, October 2",
      text: "Patmos in the day and then in the evening we will board the ferry back to Athens at 11:00 PM (overnight in our room on the ferry).",
    },
    {
      day: "Day 10, Friday, October 3",
      text: "We will arrive back to Piraeus Port in Athens at approximately 8:00 AM. From here we will travel to Ancient Corinth and stop at the Corinth Canal on the way. (overnight in Ancient Corinth).",
    },
    {
      day: "Day 11, Saturday, October 4",
      text: "Today we will tour Ancient Corinth, hike on Acro-Corinth, and visit the Cenchrea Port from which Paul sailed to Anatolia/Turkey (overnight in Ancient Corinth).",
    },
    {
      day: "Day 12, Sunday, October 5",
      text: "In the morning, we will drive to the Athens airport and depart for the USA. Our phenomenal trip will come to an end.",
    },
  ];

  // const params = useParams();

  let dates = "September 24 - October 5, 2025";
  // let itinerary = "";
  // let trip = [];
  // console.log(params.trip);

  // switch (params.trip) {
  //   case "June-2025":
  //     dates = "June 9 - 20, 2025";
  //     itinerary = itineraryOne;
  //     trip = tripOne;
  //     break;
  //   case "August_2-13-2025":
  //     dates = "August 2 - 13, 2025";
  //     itinerary = itineraryTwo;
  //     trip = tripTwo;
  //     break;
  //   case "August_16-27-2025":
  //     dates = "August 16 - 27, 2025";
  //     itinerary = itineraryThree;
  //     trip = tripThree;
  //     break;
  // }

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">{dates}</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="A map showing Patmos"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="A vlew of Patmos fromm up hight"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="The island of Patmos"
          />
        </div>
      </div>

      {trip.map((item) => (
        <div key={item.id}>
          <h2 className={styles.dayNumber}>{item.day}</h2>
          <p className="text">{item.text}</p>
        </div>
      ))}
    </div>
  );
}

export default PatmosItinerary;
