import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPicturePatmos}>
      <div className={styles.titleBox}>
        <h1>Go to the island of the apocalypse:</h1>
        <p className={styles.title}>Patmos</p>
        <p className={styles.dates}>September 24 - October 5, 2025</p>
        <div className={styles.description}>
          <p>
            Visit the Island of Patmos, where the Apostle John received and
            wrote down the book of Revelation. On this rugged and remote island,
            study the message of the Apocalypse with a small group in a
            beautiful and charming context.{" "}
          </p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
